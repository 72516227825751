import React from 'react';

import './LandingPage.css';


export default function LandingPage() {
  return (
    <div className="LandingPage">
      <div className="LandingPage-Background" />

      <div className="LandingPage-Copyright-Container">
        &copy; 2024 Joao Nogueira, all rights reserved.
      </div>
    </div>
  );
}
